//
// _table.scss
//

.table {
  @include themify() {
    color: theme-get("body-color");
    border-color: theme-get("table-border-color");
  }
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    @include themify() {
      color: theme-get("body-color");
      border-color: theme-get("table-border-color");
      background-color: theme-get("table-head-bg");
    }

  }
}

.table-bordered {
  @include themify() {
    border: $table-border-width solid theme-get("table-border-color");
  }

  th,
  td {
    @include themify() {
      border: $table-border-width solid theme-get("table-border-color");
    }
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  @include themify() {
    border-bottom-color: theme-get("table-dark-border-color");
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}


.list-group-item {
  @include themify() {
    background-color: theme-get("list-group-bg");
  }
}
