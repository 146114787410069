.text-muted {
  @include themify() {
    color: theme-get("text-muted") !important;
  }
}

.dotted {
  text-decoration: underline dotted;
}

.hidden {
  opacity: 0
}

.collapse1 {
  display:none;
}

.invalid {
border:1px solid #ff0000;
margin-right:2px;
}
