//
// _footer.scss
//
:root {
  --#{$variable-prefix}body-bg-rgb: #{to-rgb(#000)};
  --#{$variable-prefix}body-bg: #{#000};
}

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    left: 0 !important;
    height: $footer-height;
    @include themify() {
      color: theme-get("footer-color");
      background-color: theme-get("footer-bg");
    }

    @media (max-width: 991.98px) {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}
