//
// _pagination.scss
//

.page-link {
  @include themify() {
    background-color: theme-get("pagination-background");
    border: 1px solid  theme-get("pagination-border-color");
  }
}

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 0px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
    }
  }
