//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;

  @include themify() {
    background-color: theme-get("card-bg");
  }
}

.card-body {
  @include themify() {
    color: theme-get("body-color");
  }
}

.card-drop {
  @include themify() {
    color: theme-get("body-color");
  }
}

.icon-style {
  @include themify() {
    fill: theme-get("body-color");
  }
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  @include themify() {
    color: theme-get("card-title-desc");
  }
  margin-bottom: 24px;
}
