
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        @include themify() {
          fill: theme-get("gray-500");
        }
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip {
  border-radius: $border-radius;
  background-color: $black;
  padding-left: 0.4rem;
  padding-top: 0.4rem;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-size: $font-size-sm;
    font-family: $font-family-base !important;
    color: $white;
}

.apexcharts-legend-series {
    font-weight: $fw-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    @include themify() {
      stroke: theme-get("apex-grid-color");
    }
}

.apexcharts-legend-text {
    @include themify() {
      color: theme-get("gray-600") !important;
    }
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        @include themify() {
          fill: theme-get("gray-500");
        }
    }
}
