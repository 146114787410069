//
// _header.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  z-index: 1002;
  box-shadow: $box-shadow;
  @media (max-width: 991.98px) {
    left: 0;
  }

  @include themify() {
    background-color: theme-get("header-bg");
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  .dropdown {
    &.show {
      &.header-item {
        background-color: $gray-100;
      }
    }
    .dropdown-menu {
      margin-top: 0;
    }
  }
}

.navbar-brand-box {
  padding: 0 23px;
  text-align: center;
  width: $navbar-brand-box-width;
}

.logo {
  line-height: 70px;

  .logo-sm {
    display: none;
  }
}

.logo-light {
  display: none;
}

/* Search */

.app-search {

  padding: calc(#{$header-height - 38px} / 2) 23px;

  .form-control {
    border: none;
    height: 38px;
    width: 265px;
    padding-left: 10px;
    padding-right: 20px;
    background-color: rgba($topbar-search-bg, 0.07);
    color: $white;
    box-shadow: none;
    border-radius: 3px;
  }

  .form-control-invalid {
    border:1px solid #ff0000;
    margin-right:2px;
    height: 38px;
    width: 265px;
    padding-left: 10px;
    padding-right: 20px;
    background-color: rgba($topbar-search-bg, 0.07);
    color: $white;
    box-shadow: none;
    border-radius: 3px;
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    right: 10px;
    top: 0;
    border-left:1px;
  }
}

.app-search {
  span,
  input.form-control::-webkit-input-placeholder {
    color: rgba($white, 0.5);
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo {


    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  padding: calc(#{$header-height} + #{$grid-gutter-width})
    calc(#{$grid-gutter-width} / 2) $footer-height
    calc(#{$grid-gutter-width} / 2);
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  border: 0;
  border-radius: 0px;
  position: relative;
  @include themify() {
    color: theme-get("header-item-color");
  }

  @include on-hover {
    @include themify() {
      color: theme-get("header-item-color");
    }
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 23px;
    @include themify() {
      color: theme-get("header-dark-item-color");
    }
  }

  @include on-hover {
    i {
      @include themify() {
        color: theme-get("menu-item-active-color");
      }
    }
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.noti-icon-small {
  i {
    font-size: 18px;
    @include themify() {
      color: theme-get("header-dark-item-color");
    }
  }

  @include on-hover {
    i {
      @include themify() {
        color: theme-get("menu-item-active-color");
      }
    }
  }
  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.notification-item {
  .media {
    padding: 0.75rem 1rem;

    @include on-hover {
      background-color: $gray-300;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  @include themify() {
    color: theme-get("gray-600");
  }

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include on-hover {
    border-color: $border-color;
    @include themify() {
      border-color: theme-get("border-color");
    }
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}


#page-topbar {
  @include themify() {
    background-color: theme-get("header-dark-bg");
  }
}

.navbar-header {
  .dropdown .show {
    &.header-item {
      background-color: rgba($white, 0.05);
    }
  }

  .waves-effect .waves-ripple {
    background: rgba($white, 0.4);
  }
}

.header-item {
  @include themify() {
    color: theme-get("header-dark-item-color");
  }

  @include on-hover {
    @include themify() {
      color: theme-get("header-dark-item-color");
    }
  }
}

.header-profile-user {
  background-color: rgba($white, 0.25);
}

.logo-dark {
  display: none;
}

.logo-light {
  display: block;
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}


.navbar-brand-box {
  width: auto;
}

.page-content {
  margin-top: $header-height;
  padding: calc(55px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
    $footer-height calc(#{$grid-gutter-width} / 2);
}


@media (max-width: 992px) {
  .page-content {
    margin-top: 15px;
  }
}
